import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";

function WhatToDo() {
  return (
    <Section bg="secondaryRed">
      <Container>
        <div className="text-box">
          <h3 style={{marginTop: '1em'}} data-sal='slide-right' data-sal-duration='1000' data-sal-delay='100'>POPRAW KONDYCJĘ </h3>
          <p>
            Intensywne treningi poprawią Twoją kondycję i wzmocnią wytrzymałość
            oraz siłę.
          </p>
          <h3 data-sal='slide-right' data-sal-duration='1000' data-sal-delay='100'>WZMOCNIJ CHARAKTER </h3>
          <p>
            Sztuki walki to nie tylko trening wytrzymałości, ale również siła
            charakteru. Zbuduj pewność siebie i wzmocnij charakter poprzez
            systematyczne treningi.
          </p>
          <h3 data-sal='slide-right' data-sal-duration='1000' data-sal-delay='100'>ZADBAJ O SWOJE CIAŁO</h3>
          <p>
            Zdobądź wymażoną sylwetkę poprzez zróżnicowane treningi opierające
            się na rozwoju ogólnym ciała.
          </p>
        </div>
      <StaticImage className="imgx" src="../images/guard2.png"/>
      </Container>
    </Section>
  );
}

export const Section = styled.section`
  position: relative;
  color: black;
  position: relative;
  width: 100%;
  background: linear-gradient(
    to right,
    var(--lightGrey) 60%,
    var(--darkGrey) 40%
  );

  .right-up-corner, .left-bottom-corner {
    @media (max-width: 767px) and (orientation: portrait) {
      display: none;
    }
  }
  
  .right-up-corner {
    position: absolute;
    width: 8%;
    aspect-ratio: 1/1;
    background-color: var(--snow);
    top: 0;
    right: 0;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: var(--darkGrey);
        right: 0;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: var(--darkGrey);
        left: 0;
        bottom: 0;
    }
  }

  .left-bottom-corner {
    position: absolute;
    width: 8%;
    aspect-ratio: 1/1;
    background-color: var(--lightGrey);
    bottom: 0;
    right: 40%;
    transform: translate(100%, 50%);

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: var(--darkGrey);
        right: 0;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: var(--darkGrey);
        left: 0;
        bottom: 0;
    }

    .next {
        width: 100%;
        left: -50%;
        
        .last {
            position: absolute;
            width: 50%;
            height: 10%;
            background-color: var(--darkGrey);
            right: 0;
            bottom: 0;
            transform: translateY(100%);
        }
    }
  }

  .text-box {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h3 {
        text-align: left;
    }

    @media all and (orientation: portrait) {
        margin-top: 2em;
        width: 100%;
        height: 55%;
        min-height: 350px;
    }
  }

  @media all and (orientation: portrait) {
    // min-height: 100vh;
    background: linear-gradient(
        to bottom,
        var(--lightGrey) 84%,
        var(--darkGrey) 16%
    );
    overflow: hidden;
  }

  .imgx {
    width: 45;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 72%;
    min-width: 160px;
    max-width: 12%;
    overflow: hidden;

    @media all and (orientation: portrait) {
        left 53%;
        transform: translateX(-55%);
        position: absolute;
        bottom: -90px;
    }
  }
}
`;

export default WhatToDo;
