import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import { StaticImage } from "gatsby-plugin-image";

function Achievements2() {
  return (
    <Section bg="secondaryRed">
      <StaticImage className="imgx" src="../images/with-gold.png" alt='gold medal from international kungfu contest'/>
      <Container>
        <div className="text-box" data-sal='slide-left' data-sal-duration='1000'>
          <p>
            III miejsce sand full contact -75kg. Mistrzostwa Świata Shuai Jiao -
            Włochy Rzym 2014.
          </p>
          <p>
            II Miejsce Sanda 19-35lat -75kg. Puchar Polski Wushu - Warszawa
            2013.
          </p>
          <p>
            II miejsce Sanda -75kg. Mistrzostwa Europy Kuoshu - Niemcy Neu-Ulm
            2013.
          </p>
        </div>
      </Container>
    </Section>
  );
}

export const Section = styled.section`
  color: black;
  background: linear-gradient(to left, var(--snow) 60%, var(--darkGrey) 40%);
  //height: 50vh;
  //min-height: 622px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @media all and (orientation: portrait) {
    height: 100vh;
    background: linear-gradient(
        to bottom,
        var(--snow) 84%,
        var(--darkGrey) 16%
    );
  }

  .text-box {
    //border: 2px solid orange;
    text-align: right;
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    float: right;
    //font-size: 32px;
    text-align: left;
    line-height: 43px;
    padding-left: 2em;
    h3 {
      text-align: center;
    }

    @media all and (orientation: portrait) {
      width: 100%;
      height: 45%;
      float: unset;
      text-align: unset;
      min-height: 350px;
      //background-color: grey;
    }
  }

  .imgx {
    // width: 45;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 10%;
    //transform: translateX(-50%);
    min-width: 190px;
    max-width: 16%;
    overflow: hidden;

    @media all and (orientation: portrait) {
        left: 53%;
        transform: translateX(-55%);
    }
  }
`;

export default Achievements2;
