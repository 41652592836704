import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";

function ExtraCssSpace() {
  return (
    <Section bg="secondaryRed">
      <Container>
        <div className="space">
          
        </div>
      </Container>
    </Section>
  );
}

export const Section = styled.section`

  color: black;
  height: 17vh;
  //min-height: 622px;
  //position: relative;
  width: 100%;
  overflow: hidden;
  background: var(--lightGrey);
  @media all and (orientation: portrait) {
    display: none;
  }

}
`;

export default ExtraCssSpace;
