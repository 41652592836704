import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import { StaticImage } from "gatsby-plugin-image";

function Banner() {
  return (
    <Section>
      <StaticImage className="sword" src="../images/sword.jpg" alt='kungfu lady with a sword'/>
      {/* <img className="sword" src={bg} /> */}
      <div className="text1">
        <Container>
          <p>
            SANDA (wolna walka, street fighting. Wyśmienita alternatywa dla osób
            lubiących Kickboxing, Muai Thai, MMA, K1, itp.) ...... <a style={{color: "#4B88A2"}} href='https://pl.wikipedia.org/wiki/Sanda_(sport_walki)'>czytaj więcej!</a>
          </p>
          <p>
            Samoobrona (dźwignie, rzuty, obalenia. Dla wszystkich chcących
            poznać podstawy samoobrony)
          </p>
        </Container>
      </div>
      <div className="text2">
        <Container>
          <p>
            Shuai Jiao (tzw.”chińskie zapasy” w wolnym tłumaczeniu „rzucić na
            ziemię” jeden z najstarszych znanych systemów walki. Na nowo
            odzyskujący popularność. Jest stosowany przez jednostki specjalne
            policji w wielu krajach świata w tym Chin, Hong Kongu i Taiwanu
          </p>
          <p>
            Luohan Qigong to chińska metodą zachowania zdrowia przy pomocy
            oddechu i łagodnego ruchu fizycznego. Dodaje energii i radości
            sprawia, że jesteśmy szczęśliwsi, piękniejsi i zdrowsi
          </p>
        </Container>
      </div>
    </Section>
  );
}

export const Section = styled.section`
  background-color: var(--lightGrey);
  color: black;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .sword {
    order: 1;
  }

  .text1 {
    order: 2;
    @media all and (orientation: portrait) {
        order: 0;
    }
  }

  .text2 {
    order: 3;
  }

  p {
    margin: 3em 0;
  }


`;
export default Banner;
