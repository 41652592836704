import React, {useEffect} from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import Ninja from "../assets/svg-icons/Ninja.svg";
import Kick from "../assets/svg-icons/Kick.svg";
import Sparing from "../assets/svg-icons/Sparing.svg";
import BoxingGlove from "../assets/svg-icons/BoxingGlove.svg";

export const duration = '1000';

export const SectionDescription = () => {

  return <Section name="description" bg="darkGrey" id='trainings'>
    <Container name='description'>
      <FlexArticles>
        
        <div className="title">
          <h3>Zajęcia indywidualne i grupowe:</h3>
        </div>
        <div className="article" data-sal='slide-up' data-sal-duration='1000' data-sal-delay='100'>
          <Ninja className="ninja" />
          <p>
            Treningi kung-fu indywidualne i grupowe dla kobiet zawierające
            samoobrone, sande, trening ogólnorozwojowy oraz elementy oddechu i
            łagodnego ruchu fizycznego.
          </p>
        </div>
        <div className="article" data-sal='slide-up' data-sal-duration="1000"data-sal-delay='100'>
          <BoxingGlove className="ninja" />
          <p>
            Treningi indywidualne dla dzieci kung fu (sztuki walki), wolna walka
            - sanda w połączeniu z rozwijeniem wszystkich aspektów motoryki
            dziecka.
          </p>
        </div>

        <div className="article" data-sal='slide-up' data-sal-duration="1000" data-sal-delay='100'>
          <Kick className="ninja" />
          <p>
            Treningi indywidualne dla mężczyzn zawierające samoobrone, sande oraz
            trening ogólnorozwojowy.
          </p>
        </div>
        <div className="article" data-sal='slide-up' data-sal-duration="1000" data-sal-delay='100'>
          <Sparing className="ninja" />
          <p>
            Treningi grupowe dla dzieci (grupa tygrysy) kung fu (sztuki walki)
            wolna walka - sanda w grupach, podstawy zapasów i samoobrona.
            Podczas zajęć grupowych, oprócz nauki nie zabraknie zabawy
            rozwijającej zdolności motorycznych.
          </p>
        </div>
      </FlexArticles>
    </Container>
  </Section>
};

export const Section = styled.section`
  color: white;
  background-color: ${(props) => `var(--${props.bg})`};
  @media all and (orientation: portrait) {
    min-height: 100vh;
  }
`;

export const FlexArticles = styled.div`
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 100vh;
  //min-height: 723px;
  gap: 1rem;

  @media all and (orientation: landscape) {
    flex-direction: row;
  }
  .title {
    width: 100%;
    text-align: center;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .article {
    display: flex;
    align-items: center;
    flex-basis: 45%;
    gap: 1rem;
    @media all and (orientation: portrait) {
      flex-basis: auto;
    }

    .ninja {
      width: 10%;
    }
    p {
      width: 90%;
      @media  (orientation: portrait) and (max-width: 500px) {
        font-size: 1em;
      }

    }
  }
`;
