import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import { StaticImage } from "gatsby-plugin-image";

function Achievements() {
  return (
    <Section bg="secondaryRed">
        <Container>
            <div className="text-box" data-sal='slide-right' data-sal-duration='1000'>
              <h3>Wybrane osiagniecia sportowe</h3>
              <p>I miejsce full contakt Lei Tai w kategorii open. Mistrzostwa Świata Chińskiego Kuoshu - Rostov, Rosja 2019. </p>
              <p>II Miejsce Qingda 19-35lat -75kg. Mistrzostwa Europy Wushu Tradycyjnego - Bułgaria Stara Zagora 2015.</p>
            </div>
        </Container>
        <StaticImage className="imgx" src='../images/plecy-croped.png' alt='kungfu lady shows her biceps'/>
    </Section>
  );
}

export const Section = styled.section`
  color: white;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    to right,
    #F02D3A 60%,
    #252627 40%
  );
  .text-box {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media all and (orientation: portrait) {
      width: 100%;
      height: 50%;
      min-height: 350px;
    }
    h3 {
        text-align: center;
    }
  }
  @media all and (orientation: portrait) {
    height: 100vh;
    background: linear-gradient(
        to bottom,
        #F02D3A 84%,
        #252627 16%
    );
  }
  .imgx {
    width: 45;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 65%;
    min-width: 360px;
    max-width: 25%;
    overflow: hidden;
    @media all and (orientation: portrait) {
        left: 53%;
        transform: translateX(-55%);
    }
  }
`;

export default Achievements;
