import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import BlueDot from "../assets/svg-icons/BlueDot.svg"
import { StaticImage } from "gatsby-plugin-image";

function SectionAbout() {
  return (
    <Section2 bg="lightGrey" id='about'>
      <Container>
        <Flex>
        <h3 style={{marginTop: '2em'}}>O mnie</h3>
        <p>
          Jestem absolwentką Akademii Fizycznej im. J. Kukuczki w Katowicach.
        </p>
        <p>
          Od 2007 roku ćwiczę styl Choy Lee Fut Kung Fu (pod kierunkiem sifu
          Janusza Koryczana), a od 2012 również Shuai Jiao (chińskie zapasy).
        </p>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
          <BlueDot />
          <p>Instruktor Hung Sing Gwoon Chan Family.</p>
        </div>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
            <BlueDot />
          <p>Instruktor pływania.</p>
        </div>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
            <BlueDot />
          <p>Były członek Kadry Polski PZ Wushu.</p>
        </div>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
            <BlueDot />
          <p>Instruktor fitnessu funkcjonalnego.</p>
        </div>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
            <BlueDot />
          <p>Sędzia I klasy Polskiego Związku Wushu.</p>
        </div>
        <div className="profession flex-row" data-sal='slide-left' data-sal-duration='1000' data-sal-delay='100'>
            <BlueDot />
          <p>Trener personalny.</p>
        </div>
        
        </Flex>
      </Container>
    </Section2>
  );
}


export const Section2 = styled.section`
  color: black;
  min-height: 622px;
  background-color: ${(props) => `var(--${props.bg})`};
  background-image: url('../images/stamp.png');
  background-repeat: no-repeat;
  background-position-x: right;
  @media all and (orientation: portrait) {
    height: 100vh;
    background-position-x: center;
    background-position-y: bottom;
  }
`;

export const Flex = styled.div` 
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media all and (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  p {
    width: 100%;
  }
  
  h3 {
    text-align: center;
  }

  .profession {
      @media all and (orientation: landscape) {
        width: 45%;
      }
  }
  
  .flex-row {
    display: flex;
    align-items: center;
  }

  imgx {
    display: block;
    @media (orientation: landscape) {
      display: none;
    }
  }

  & > * {
    margin: 0.3em;
    @media (orientation: landscape) {
      margin: 1em;
    }
  }
`

export default SectionAbout;
