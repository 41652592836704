import React from "react";
import styled from "styled-components";
import { Container } from "./styled-components/Containers";
import { StaticImage } from "gatsby-plugin-image";

function Footer() {
  return (
    <Section bg="darkGrey" id='contact'>
      <Container>
        <div className="column-flex">
          <StaticImage className="imgx" src='../images/na-sali.jpg' alt='lady performing kick with boxing bag'/>
          <div className="flexy-texts">
            <p>Telephone/whatsapp: +31(0)685393272</p>
            <p>Adres mailowy: kungfuclubnl@gmail.com</p>
            <a href='https://www.facebook.com/profile.php?id=100087951404531'>
              <p className="only-mobile">napisz do mnie na fb</p>
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export const Section = styled.section`
  color: white;
  background-color: ${(props) => `var(--${props.bg})`};
  min-height: 75vh;

  .imgx {
    width: 100%;
    max-width: 900px;
    display: block;
    margin: 0 auto;
    padding-top: 2em;
  }

  .column-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    @media (max-width: 767px) and (orientation: portrait) {
      height: 75vh;
    }
  }

  .flexy-texts {
    text-align: center;
    margin: 2em 0;
    @media all and (orientation: landscape) {
        display: flex;
        justify-content: space-between;
    }
  }

  .only-mobile {
    display: none;
    color: #0BACF0;
    text-decoration: underline;

    @media all and (orientation: portrait) {
        display: block;
    }
    @media (orientation: portrait) and (max-width: 378px) {
      font-size: 0.6em;
    }
  }
`;

export default Footer;
