import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import FbIcon from "../assets/svg-icons/fb-icon.svg";
import InstaIcon from "../assets/svg-icons/Insta-icon.svg";
import LineIcon from "../assets/svg-icons/Line-icon.svg";
import ThroughIcon from "../assets/svg-icons/Through-icon.svg";
import MouseIcon from "../assets/svg-icons/Mouse-icon.svg";
import Left from "../assets/svg-icons/left.svg"
import Right from "../assets/svg-icons/right.svg"
import {
  SectionDescription,
} from "../components/SectionDescription";
import SectionAbout from "../components/SectionAbout";
import Achievements from "../components/Achievements";
import Achievements2 from "../components/Achievements2";
import WhatToDo from "../components/WhatToDo";
import ExtraCssSpace from "../components/ExtraCssSpace";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

function Home({ data : { a: { childImageSharp }, b: { nodes } } }) {
  const [index, setIndex] = useState(1);
  const { hero_text } = nodes[index].frontmatter;
  const { hero_image, hero_image_alt, hero_num } = nodes[index].frontmatter;
  const image = getImage(hero_image);
  const bgChinaImage = getImage(childImageSharp)
  const bgImage = convertToBgImage(bgChinaImage)
  const pseudo = useRef(null);

  const btnHandler = () => {
    if (index === 0) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  };

  return (
    <Layout pageTitle="Szkola Kungfu w Holandii ">
      <HomeDiv id='home'>
        <Nav />
        <BackgroundImage
          style={{position: 'unset', background: 'rgb(37, 38, 39, .8)', backgroundPositionY: '-290px', backgroundPositionX: '-320px'}}
          className="a"
          Tag="div"
          {...bgImage}
          preserveStackingContext
        >
        </BackgroundImage>
        <div className="b"></div>
        <div className="overlap">
          <div className="hero-box info" ref={pseudo}>
            <div className="header-container">
              <h1>Treningi</h1>
              <h2>KUNG-FU</h2>
            </div>
            <article>
              <p>{hero_text}</p>
            </article>
          </div>

          <div className="hero-box dominika">
            <GatsbyImage
              image={image}
              style={{ width: hero_num === 1 ? '57%' : '72%', left: hero_num === 1 ?  '6%': '0', top: '4%'}}
              alt={hero_image_alt}
            />
          </div>
          <div className="hero-extras">
          <a className='icon-fb' href='https://www.facebook.com/profile.php?id=100087951404531' target="_blank"><FbIcon /></a>
          <a className='icon-insta' href='https://www.instagram.com/domiiihe/' target="_blank"><InstaIcon /></a>  
            <LineIcon className="icon-line" />
            <ThroughIcon className="icon-through" />
            <MouseIcon className='icon-mouse'/>
          </div>
          <div className="spacer" style={{"order": 5}}></div>
        </div>
        <button className="btn-left" onClick={btnHandler} aria-label="display another image"><Left style={{width: '30%'}}/></button>
        <button className="btn-right" onClick={btnHandler} aria-label="display another image"><Right style={{width: '30%'}}/></button>
      </HomeDiv>
      <SectionDescription />
      <SectionAbout />
      <Achievements />
      <Achievements2 />
      <WhatToDo />
      {/* <ExtraCssSpace /> */}
      <Banner />
      <Footer />
    </Layout>
  );
}

export const query = graphql`
query {

  a: file(relativePath: {eq: "CHINY.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 10, width: 500)
    }
  }

  b: allMdx(
      filter: {
        frontmatter: {
          hero_image: { sourceInstanceName: { eq: "dominika-data" } }
        }
      }
    ) {
      nodes {
        frontmatter {
          hero_image_alt
          hero_text
          hero_image {
            childImageSharp {
              gatsbyImageData(quality: 7, width: 800)
            }
          }
          hero_num
        }
      }
    }
  }
`;

const HomeDiv = styled.div`
  scroll-snap-align: start;
  font-size: 1em;
  color: white;
  width: 100%;
  min-height: 100vh;
  //min-height: 600px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  // @media (max-width: 422px) and (max-height: 822px) {
  //   border: 2px solid purple;
  // }

  .a {
    grid-column: 1/7;
    grid-row: 1/13;    
    background-size: cover;
    background-position-y: -60px;
  }
  .b {
    grid-column: 7/13;
    grid-row: 1/13;
    background-color: var(--primaryRed);
    @media (max-width: 767px) {
      grid-column: 7/13;
      grid-row: 1/13;
    }
  }

  .overlap {
    grid-column: 4/13;
    grid-row: 4/13;
    //border: 2px solid white;
    background-color: #dd0426;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 767px) and (orientation: portrait) {
      grid-column: 3/13;
      grid-row: 4/13;
      flex-direction: column;
    }
  }

  .info {
    width: 40%;
    position: relative;
    left: -20%;
    top: -10%;
    order: 1;
    line-height: 2.4em;
      @media (max-width: 767px) and (orientation: portrait) {
        line-height: 2.9em;
      }

    @media (max-width: 767px) and (orientation: portrait) {
      left: unset;
      width: 80%;
      order: 2;
    }

    article {
      font-family: Poppins, sans-serif;
      line-height: 1.4em;
      
      @media (max-width: 767px) and (orientation: portrait) {
        line-height: 1.9em;
        padding: 2em 0;
      }
      @media (min-width: 1000px)  {
        width: 120%;
      }
    }
  }

  .header-container {
    font-family: Quando, serif;
    letter-spacing: 6px;
    h1 {
      text-align: left;
      padding-top: 1em;
    }
    h2 {
      text-align: right;
    }
  }

  .hero-box.dominika {
    width: 45%;
    display: grid;
    place-items: center;
    position: relative;
    left: -10%;
    order: 2;
    aspect-ratio: 1/1 !important;
    border-radius: 50%;
    background-color: var(--secondaryRed);
    overflow: hidden;

    @media (max-width: 767px) and (orientation: portrait) {
      width: 50%;
      left: unset;
      order: 1;
      margin-top: 1.5em;
      margin-bottom: 4em;
      
    }
  }

  .hero-extras {
    width: 10%;
    align-self: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 3;

    & > * {
      margin: 1em 0;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      order: 3;
      //position: relative;
      //top: -5%;
    }

    .icon-line {
      @media (orientation: portrait) and (max-width: 767px) {
        display: none;
      }
    }
    .icon-through {
      display: none;

      @media (orientation: portrait) and (max-width: 767px) {
        display: inline-block;
        width: 20%;
      }
    }
    svg {
      @media (orientation: portrait) and (max-width: 767px) {
        max-width: 20px;
      }
    }
    svg.icon-mouse {
      @media (orientation: portrait) and (max-width: 767px) {
        position: relative;
        top: -5px;
        max-width: 25px;
      }
    }
  }

  //buttons #############################################
  button {
    aspect-ratio: 1/1;
    width: 70%;
    min-width: 50px;
    border: none;
    grid-row: 11/12;
    transform: translateY(-30%);
    
    @media (orientation: portrait) and (max-width: 767px) {
      width: 100%;
      grid-row: 10/11;
    }
    @media screen and (max-height: 808px){
      transform: translateY(100%);
    }
  }
  .btn-left {
    grid-column: 3/4;
    transform: translate(45%, -30%);
    background-color: var(--primaryRed);

    @media (orientation: portrait) and (max-width: 767px) {
      transform: translate(0%, 50%);
      grid-column: 2/3;
    }
    @media screen and (max-height: 808px){
      transform: translateY(100%);
    }
  }
  .btn-right {
    grid-column: 4/5;
    background-color: var(--darkGrey);

    @media (orientation: portrait) and (max-width: 767px) {
      grid-column: 3/4;
      transform: translate(0%, 50%);
    }
    @media screen and (max-height: 808px){
      transform: translateY(100%);
    }
  }

`

export default Home;

export const Head = () => (
  <>
    <title>Szkoła Sztuk Walki Kungfu w Holandii</title>
    <meta 
      name='description'
      content="Profesjonalne treningi indywidualne i grupowe dla dzieci i dorosłych w Holandii. Trening sztuk walki i samoobrony jest prowadzona przez trenerkę z wieloletnim doświadczeniem."
    />
    <meta 
      name='keywords'
      content="kungfu, wushu, treningi, szkoła, school, martial arts, Tilburg, Holandia, The Netherlands, Nederland, Self defence, samoobrona, sanda"
    />
      <meta name="image" content={`https://www.kungfuclub.nl/images/facebook_post_improved.jpg`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content='kung-fu school' />
      <meta name="twitter:url" content='https://twitter.com/Chris87486670' />
      <meta name="twitter:description" content='treningi sztuki walki oraz samoobrony' />
      <meta name="twitter:image" content={`https://www.kungfuclub.nl/images/facebook_post_improved.jpg`} />
      <meta name="twitter:creator" content='@Chris87486670' />
      <meta property='og:image' content={ `https://www.kungfuclub.nl/images/facebook_post_improved.jpg` } />
      <meta property='og:locale' content='pl_PL' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content='Szkola kungfu w Niderlandach' />
      <meta property='og:description' content="treningi sztuki walki oraz samoobrony" />
      <meta property='og:url' content='https://www.facebook.com/profile.php?id=100087951404531' />
    <link
      rel="canonical"
      href="https://www.kungfuclub.nl"
    />
    <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" />
  </>
) ;
